<template>
    <div v-if="orderData">
        <PageSection>
            <div class="d-flex justify-content-between align-items-center mb-4">
                <div>
                    <h1 class="h4 mb-0">{{ orderData.patient_name }} / #{{orderData.id}}</h1>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col d-flex align-items-center">
                    <div class="h5 mb-0">Status: {{orderData.status.description}}</div>
                </div>
                <div class="col d-flex align-items-center justify-content-end">
                    <div class="text-right">
                        <!-- <router-link v-if="orderData && orderData.invoice_id && $filters.canAccess($accessLevel.sensitive)" class="btn btn-sm btn-secondary ms-1" :to="{ name: 'Transaction', params: { account_id: orderData.account.id, id: orderData.invoice_id }}">Invoice</router-link> -->
                        <span v-if="orderData && orderData.invoice_id && $filters.canAccess($accessLevel.sensitive)" class="btn btn-sm btn-secondary ms-1" @click="openTransactionPdf">Invoice</span>
                        <span v-if="orderData && orderData.invoice_id" class="btn btn-sm btn-secondary ms-1" @click="openPatientStatement">Patient Statement</span>
                        <router-link v-if="orderData.consignment_id" class="btn btn-sm btn-secondary ms-1" :to="{ name: 'Consignment', params: { id: orderData.consignment_id }}">Track delivery</router-link>
                        <router-link v-if="orderData.is_returnable_by_dentist" class="btn btn-sm btn-secondary ms-1" :to="{ name: 'OrderReturn', params: { id: orderData.id }}">Return</router-link>
                        <router-link v-if="orderData.temp_order_id && orderData.temp_order_id !== null" class="btn btn-sm btn-secondary ms-1" :to="{ name: 'OrderCreateSend', params: { id: orderData.temp_order_id }}">Print Ticket</router-link>
                    </div>
                </div>
            </div>
            <div class="progress mb-3">
                <div class="progress-bar bg-fresh-green" :style="{width: percentageComplete + '%'}"></div>
            </div>
            <div class="progress-events d-flex justify-content-between">
                <div v-for="event in orderData.events" v-bind:key="event.label" class="text-center" style="flex-basis: 0; flex-grow: 1;">
                    <div>{{event.label}}</div>
                    <small v-if="event.date">{{ $filters.formatDate(event.date) }}</small>
                    <small v-if="!event.date">-</small>
                </div>
                
            </div>
        </PageSection>

        <PageSection>
            <PageSectionHeading>Details</PageSectionHeading>
            <div class="row">
                <div class="col-md">
                    <div><strong>Practice: </strong>{{orderData.practice.company_name}}</div>
                    <div><strong>Dentist: </strong>{{orderData.dentist.first_name}} {{orderData.dentist.surname}}</div>
                </div>
                <div class="col-md">
                    <div><strong>Service level: </strong>{{orderData.standard.description}}</div>
                    <div><strong>Requested delivery: </strong>{{ $filters.formatDate(orderData.patient_date) }}</div>
                    <div v-if="orderData.shade && orderData.shade.length"><strong>Shade: </strong>{{orderData.shade}}</div>
                </div>
            </div>
        </PageSection>
        <PageSection v-if="alignerDetailData">
            <PageSectionHeading>Aligner Details  <router-link v-if="alignerDetailData.treatment_type === '-' " :to="`/orders/${orderData.id}/aligner-update`" class="btn btn-primary" style="float:right">Update Aligner</router-link></PageSectionHeading>
            <div class="row" v-bind:key="alignerDetailData.treatment_type">
                <div class="col-md">
                    <div><strong>Treatment Type: </strong>{{alignerDetailData.treatment_type}}</div>
                    <div><strong>Arches: </strong>{{alignerDetailData.arches}}</div>
                    <div><strong>Trim Line: </strong>{{alignerDetailData.trim_line}}</div>
                </div>
                <div class="col-md">
                    <div><strong>IPR: </strong>{{alignerDetailData.ipr_requested}}</div>
                    <div><strong>Attachments: </strong>{{alignerDetailData.attachments_needed}}</div>
                </div>
            </div>
            <br/>
            <div class="row">
                <div><strong>Patient Complaints: </strong>{{alignerDetailData.complaint}}</div>
                <div><strong>Other Notes/Instructions: </strong>{{alignerDetailData.comment}}</div>
            </div>
            <div class="mt-5" v-if="alignerDetailData && alignerDetailData.teeth_chart && alignerDetailData.teeth_chart.length">
                <ToothAnnotations :alignerDetailData="alignerDetailData" />
            </div>
        </PageSection>
        <PageSection v-show="hasTimeline && alignerDetailData">
            <Timeline @hasTimeline="hasTimeline = $event"/>
        </PageSection>        
        <PageSection v-if="filesData && filesData.length">
            <PageSectionHeading>Files</PageSectionHeading>
            <div v-if="filesData && filesData.length">
                <div class="row">
                    <div v-for="file of filesData" v-bind:key="file.id" class="col-12 col-md-6 col-xl-4 mb-4">
                        <div class="uploaded-file">
                            <a :href="file.url" target="_blank" class="text-truncate"><i class="bi bi-download pe-2"></i> {{ file.file_name }}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!filesData.length" class="pb-4">
                No files have been added to this order.
            </div>
        </PageSection>

        <div v-if="stageData && stageData.length">
            <PageSection v-for="(stage, index) of stageData" v-bind:key="index">
                <div class="row">
                    <div class="col-md">
                        <PageSectionHeading v-if="stage.is_temp_order">Pending Stage</PageSectionHeading>
                        <PageSectionHeading v-else>Stage {{index + 1}}</PageSectionHeading>
                    </div>
                </div>
                <div class="mb-3">
                    <table class="product-table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th class="column-number">Qty</th>
                                <th class="column-number">Price</th>
                                <th class="column-number">Total</th>
                            </tr>
                        </thead>
                        <tbody v-for="(product, productIndex) of stage.products" v-bind:key="productIndex" class="mb-1">
                            <tr>
                                <td>
                                    <div v-if="product.entity_type === 'P'">
                                        {{product.code}} - {{product.name}}
                                    </div>
                                    <div v-if="product.entity_type === 'M'" class="ps-2">
                                        &gt; {{product.name}}
                                    </div>
                                </td>
                                <td class="column-number ps-3">{{product.quantity}}</td>
                                <td class="column-number ps-3">
                                    <span v-if="product.unit_price">&pound;{{product.unit_price}}</span>
                                    <span v-if="!product.unit_price">TBC</span>
                                </td>
                                <td class="column-number ps-3">
                                    <span v-if="product.completion_price">&pound;{{product.completion_price}}</span>
                                    <span v-if="!product.completion_price">TBC</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <span v-if="stage.received_datetime" class="me-4">
                        <strong>Received:</strong> {{$filters.formatDate(stage.received_datetime)}}
                    </span>
                    <span v-if="stage.dispatch_datetime">
                        <strong>Dispatched:</strong> {{$filters.formatDate(stage.dispatch_datetime)}}
                    </span>
                </div>
                <div v-for="(note, noteIndex) of stage.notes" v-bind:key="noteIndex">
                    <div><strong>Instructions: </strong>{{note}}</div>
                </div>
            </PageSection>
        </div>


        <PageSection isLastSection="true">
            <div class="row">
                <div class="col">
                    <div class="h5 mb-0">
                        Total
                    </div>
                </div>
                <div class="col d-flex justify-content-end">
                    <table class="product-table product-table-collapsed">
                        <tr v-if="orderData.delivery_charges > 0 || orderData.express_surcharge > 0 || orderData.voucher_discounts > 0">
                            <td class="column-number"><strong>Sub-total:</strong></td>
                            <td class="column-number ps-4">£{{orderData.sub_total}}</td>
                        </tr>
                        <tr v-if="orderData.delivery_charges > 0">
                            <td class="column-number"><strong>Delivery:</strong></td>
                            <td class="column-number ps-4">£{{orderData.delivery_charges}}</td>
                        </tr>
                        <tr v-if="orderData.express_surcharge > 0">
                            <td class="column-number"><strong>Express:</strong></td>
                            <td class="column-number ps-4">£{{orderData.express_surcharge}}</td>
                        </tr>
                        <tr v-if="orderData.voucher_discounts > 0">
                            <td class="column-number"><strong>Voucher Discounts:</strong></td>
                            <td class="column-number">-£{{orderData.voucher_discounts}}</td>
                        </tr>
                        <tr>
                            <td class="column-number"></td>
                            <td class="column-number ps-4"><strong>£{{orderData.sum_total}}</strong></td>
                        </tr>
                    </table>
                </div>
            </div>
        </PageSection>

      </div>
</template>

<style scoped lang="scss">
    .uploaded-file
    {
        border: $border-width*2 solid $swift-lighter-grey;
        @include border-radius($border-radius);
        padding: calc($spacer / 2) $spacer;

        a
        {
            display: block;
        }
    }

    .product-table
    {
        border-collapse: collapse;
        width: 100%;
        line-height: 1em;

        &.product-table-collapsed
        {
            width: auto;
        }

        td,
        th
        {
            vertical-align: top;
            padding-top: 0.3em;
            padding-bottom: 0.3em;
        }

        .column-number
        {
            width: 15%;
            white-space: nowrap;
            text-align: right;
        }
    }

    .progress-events
    {
        color: $swift-mid-grey;
    }
</style>

<script>
    import api from "@/services/api";
    import base64 from "@/services/base64";
    import PageSection from '@/components/PageSection.vue';
    import PageSectionHeading from '@/components/PageSectionHeading.vue';
    import Timeline from '@/components/Timeline.vue';
    import ToothAnnotations from '@/components/ToothAnnotations.vue';

    export default {
        props: {
            id: {
                required: true
            }
        },
        components: {            
            PageSection,
            PageSectionHeading,
            Timeline,
            ToothAnnotations,
        },
        data() {
            return {
                orderData: null,
                stageData: null,
                filesData: null,
                alignerDetailData: null,
                activeTab: 'details',
                hasTimeline: false,
            };
        },
        mounted () {
            this.fetchOrderData();
        },
        computed: {
            percentageComplete () {
                var events = this.orderData.events;
                var eventCount = events.length; // How many events are we dealing with?
                var indexedEvents = events.map((event, index) => ({index: index, completed: event.completed})); // Create an array of events retaining their index and status
                var completedEvents = indexedEvents.filter(event => event.completed); // Filter out incomplete events
                var lastCompletionIndex = completedEvents.at(-1).index; // Find the index of the last completed event
                return (100 / eventCount) * (lastCompletionIndex+1);
            },
        },
        methods: {
            fetchOrderData: function() {                
                api
                .get('/api/v1/orders/' + this.id)
                .then((response) => {
                    this.orderData = response.data.data;
                    this.stageData = response.data.data.stages;
                    this.alignerDetailData = this.orderData.aligner_detail;
                    this.fetchFilesData();
                })
                .catch(() => {
                    console.log('Catch order error');
                });
            },
            fetchFilesData: function() {                
                api
                .get('/api/v1/orders/' + this.id + '/files')
                .then((response) => {
                    this.filesData = response.data.data;
                })
                .catch(() => {
                    console.log('Catch files error');
                });
            },
            setActiveTab: function(tabName) {                
                this.activeTab = tabName;
            },
            openPatientStatement: function() {
                var pdfWindow = window.open();
                pdfWindow.document.title = "Loading...";

                api
                .get('/api/v1/accounts/' + this.orderData.account.id + '/transactions/' + this.orderData.invoice_id + '/patient-statement')
                .then((response) => {
                    var blob = base64.toBlob(response.data, 'application/pdf');
                    pdfWindow.location = URL.createObjectURL(blob);
                })
                .catch((error) => {
                    pdfWindow.close();
                    console.log('Catch patient statement pdf error', error);
                    alert('There was an error downloading this patient statement, please try again later.');
                });
            },
            openTransactionPdf: function() {
                var pdfWindow = window.open();
                pdfWindow.document.title = "Loading...";

                api
                .get('/api/v1/accounts/' + this.orderData.account.id + '/transactions/' + this.orderData.invoice_id + '/pdf')
                .then((response) => {
                    var blob = base64.toBlob(response.data, 'application/pdf');
                    pdfWindow.location = URL.createObjectURL(blob);
                })
                .catch((error) => {
                    pdfWindow.close();
                    console.log('Catch transaction pdf error', error);
                    alert('There was an error downloading this invoice, please try again later.');
                });
            },
        },
    }

</script>